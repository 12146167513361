<template>
  <div class="container">
    <div class="title"></div>
    <div class="welcome"></div>
    <div class="login-box">
      <el-form :model="form" label-width="0px" :show-message="false" ref="form" :rules="rules">
        <el-form-item class="input-container" prop="username">
          <div class="input-1">
            <div class="input-prefix">
              <img src="@/assets/images/login/icon-user.png" class="icon1" />
            </div>
            <el-input class="input-content" v-model="form.username" placeholder="请输入账号" />
            <div class="input-suffix"></div>
          </div>
        </el-form-item>
        <el-form-item class="input-container" prop="password">
          <div class="input-2">
            <div class="input-prefix">
              <img src="@/assets/images/login/icon-password.png" class="icon2" />
            </div>
            <el-input class="input-content" v-model="form.password" placeholder="请输入密码" :type="showPassword ? 'text': 'password'" />
            <div class="input-suffix">
              <img v-if="!showPassword" src="@/assets/images/login/show-password.png" class="icon3" @click="changeShowPassword" />
              <img v-else src="@/assets/images/login/hide-password.png" class="icon4" @click="changeShowPassword" />
            </div>
          </div>
        </el-form-item>
      </el-form>
      <div class="button" @click="submit"></div>
    </div>
  </div>
</template>

<script>
import { setToken } from "@/libs/login.js";
import { login } from "@/api/index.js";
export default {
  name: "login",
  components: {
    
  },
  data() {
    return {
      form: {
        username: '',
        password: '',
      },
      showPassword: false,
      rules: {
        username: [
          { required: true, message: '请输入账号', trigger: 'blur' },
        ],
        password: [
          { required: true, message: '请输入密码', trigger: 'blur' },
        ],
      }
    };
  },
  mounted() {
    
  },
  methods: {
    changeShowPassword(){
      this.showPassword = !this.showPassword;
    },
    submit(){
      this.$refs['form'].validate((valid, err)=>{
        if(!valid){
          this.$message.error(err[Object.keys(err)[0]][0]['message']);
        }else{
          login(this.form).then(res=>{
            if(res.code == 0){
              setToken('ok');
              this.$router.push('/');
            }else{
              this.$message.error(res.msg);
            }
            // console.log(res);
          })
        }
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.container{
  width: 100%;
  min-height: 100vh;
  background: url(~@/assets/images/login/bg.png) no-repeat #000511;
  background-size: 1920px 1080px;
  object-fit: contain;
  display: flex;
  flex-direction: column;
  align-items: center;
  .title{
    height:86px;
    width: 100%;
    background: url(~@/assets/images/login/title.png) no-repeat #000511;
    background-size: 100% 100%;
  }
  .welcome{
    margin-top: 177px;
    height:69px;
    width: 1270px;
    background: url(~@/assets/images/login/welcome.png) no-repeat;
    background-size: 100% 100%;
  }
  .login-box{
    margin-top: -7px;
    width: 572px;
    height: 381px;
    background: url(~@/assets/images/login/box-bg.png) no-repeat;
    background-size: 100% 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 71px;
    box-sizing: border-box;
    ::v-deep .el-form-item{
      margin-bottom: 0;
    }
    .input-container{
      margin-top: 29px;
      &:first-child{
        margin-top: 0px;
      }
    }
    .input-1,.input-2{
      display: flex;
      align-items: center;
      width: 382px;
      height: 65px;
      .input-prefix{
        width: 70px;
        height: 65px;
        display: flex;
        align-items: center;
        justify-content: center;
      }
      .input-content{
        flex: 1;
      }
      .input-suffix{
        width: 70px;
        height: 65px;
        display: flex;
        align-items: center;
        justify-content: center;
      }
      ::v-deep .input-content{
        .el-input__inner{
          color: #4188AF;
          font-size: 16px;
          background-color: transparent;
          border: 0px;
          padding: 0px;
        }
        input::-webkit-input-placeholder {
          color: #4188AF;
        }
        input::-moz-input-placeholder {
          color: #4188AF;
        }
        input::-ms-input-placeholder {
          color: #4188AF;
        }
      }
      .icon1{
        width: 20px;
        height: 20px;
      }
      .icon2{
        width: 19px;
        height: 22px;
      }
      .icon3{
        width: 24px;
        height: 18px;
        cursor:pointer;
      }
      .icon4{
        width: 32px;
        height: 26px;
        cursor:pointer;
      }
    }
    .input-1{
      background: url(~@/assets/images/login/input-bg-1.png) no-repeat;
      background-size: 100% 100%;
    }
    .input-2{
      background: url(~@/assets/images/login/input-bg-2.png) no-repeat;
      background-size: 100% 100%;
    }
    .button{
      background: url(~@/assets/images/login/login-button.png) no-repeat;
      background-size: 100% 100%;
      width: 195px;
      height: 64px;
      margin-top: 50px;
      cursor: pointer;
    }
  }
}
</style>